<template>
  <div class="w-gradual">
    <slot name="top"></slot>
    <slot name="login"></slot>
    <div class="w-gradual-group" ref="sliderGroup">
      <img v-for="(item, index) in imgs"
        :src="item"
        :key="item"
        :class="{active: currentPageIndex == index}" />
    </div>
    <div class="w-dots">
      <span class="w-dot" v-for="(item, index) in imgs" :class="{active: currentPageIndex == index}" :key="index" @click="select(index)"></span>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      loop: {
        type: Boolean,
        default: true
      },
      autoPlay: {
        type: Boolean,
        default: true
      },
      interval: {
        type: Number,
        default: 4000
      },
      imgs: {
        type: Array,
        default: function () {
          return []
        }
      }
    },
    data: function () {
      return {
        dots: [],
        currentPageIndex: 0
      }
    },
    created() {
    },
    mounted: function () {
      this.timer = setInterval(() => {
        this.currentPageIndex = (this.currentPageIndex + 1) % this.imgs.length;
      }, this.interval);
      this.initDots();
    },
    methods: {
      initDots: function () {
        this.dots = new Array(this.imgs.length);
      },
      select: function (index) {
        clearInterval(this.timer);
        this.currentPageIndex = index;
        this.timer = setInterval(() => {
          this.currentPageIndex = (this.currentPageIndex + 1) % this.imgs.length;
        }, this.interval);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .w-gradual {
    position: relative;
    width: 100%;
    overflow: hidden;

    .w-gradual-group {
      >* {
        position: absolute;
        // width: 100%;
        // transform: translateX(-50%);
        // left: 50%;
        // background-repeat: no-repeat;
        // background-position: center;
        // background-size: cover;
        top: 0;
        width: 100%;
        height:  100%;
        transition: all 0.8s ease;
        opacity: 0;

        &.active {
          opacity: 1;
        }
      }
    }

    .w-dots {
      position: absolute;
      bottom: 12px;
      width: 100%;
      text-align: center;

      .w-dot {
        cursor: pointer;
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: hsla(0, 0%, 100%, .5);
        margin: 0 4px;

        &.active {
          width: 20px;
          border-radius: 5px;
          background: hsla(0, 0%, 100%, .8);
        }
      }
    }
  }
</style>
